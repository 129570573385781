<template>
	<div class="profile-anketa">
		<div class="profile-anketa__title">{{ $t('titles.anketa') }}</div>

		<tk-progress
			class="dashboard"
			type="dashboard"
			:percentage="$store.getters.profile_percent"
			:show-text="false"
		/>

		<div class="profile-anketa__fullness profile-fullness">
			<router-link to="anketa/edit-photo" class="profile-fullness__photo">
				<tk-image
					:src="$store.getters.avatar"
					class="profile-fullness__photo--avatar"
					:alt="$t('images.alts.user.photo')"
					width="100"
					height="100"
				/>
			</router-link>
			<div class="profile-fullness__is-filled-in">
				Профиль заполнен на {{ $store.getters.profile_percent }}%
			</div>
		</div>

		<form @submit.prevent class="profile-form">
			<legend class="profile-form__title">Личные данные</legend>

			<div class="input-wrapper">
				<tk-input
					v-model="surname"
					type="text"
					@input="clearError('surname')"
					:error="errors.surname"
					:isClearButton="false"
					placeholder="Фамилия"
					autocomplete="off"
				/>
			</div>

			<div class="input-wrapper">
				<tk-input
					v-model="name"
					type="text"
					@input="clearError('name')"
					:error="errors.name"
					:isClearButton="false"
					placeholder="Имя"
					autocomplete="off"
				/>
			</div>

			<div class="input-wrapper">
				<tk-input
					v-model="patronymic"
					type="text"
					@input="clearError('patronymic')"
					:error="errors.patronymic"
					:isClearButton="false"
					placeholder="Отчество"
					autocomplete="off"
				/>
			</div>

			<div class="input-wrapper">
				<tk-input
					v-model="phone"
					type="tel"
					@input="clearError('phone')"
					:error="errors.phone"
					:isClearButton="false"
					placeholder="Номер телефона"
					autocomplete="off"
				/>
				<a @click.prevent="changePhone" class="input-wrapper__change">
					изменить
				</a>
			</div>

			<div class="input-wrapper">
				<tk-input
					v-model="email"
					type="email"
					placeholder="Почта"
					autocomplete="off"
					@input="clearError('email')"
					:error="errors.email"
					:isClearButton="false"
				/>
			</div>

			<div class="input-wrapper">
				<p class="input-wrapper__text">
					После смены почты, на новый адрес будет выслано письмо для
					подтверждения. Если письмо не пришло, проверьте папку “спам”.
				</p>
			</div>

			<div class="input-wrapper">
				<div class="input-wrapper__input">
					<span class="input-wrapper__placeholder">Пол</span>
					<tk-select
						class="select"
						v-model="gender"
						:teleported="false"
						placeholder="-"
					>
						<tk-option
							v-for="item in genders"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</tk-select>
				</div>
			</div>

			<div class="input-wrapper">
				<div class="input-wrapper__input">
					<span class="input-wrapper__placeholder">Дата рождения</span>
					<tk-datepicker
						popper-class="date-select"
						v-model="birth"
						type="date"
						placeholder="-"
						:clearable="false"
						:teleported="false"
						format="DD MMMM YYYY"
						value-format="YYYY-MM-DD"
					/>
				</div>
			</div>

			<Questions :content="career" @update="updateQuestion($event, true)" v-if="career" />
			<Questions :content="about" @update="updateQuestion($event, false)" v-if="about" />
			<Hobbies :content="hobbies" @update="($event) => (hobbies = $event)" />

      <span class="profile-form__line" />

			<div class="input-wrapper">
				<tk-link
					:height="55"
					kind="common-brown"
					to="anketa/identification"
					:disabled="isConfirmedIdentity || isInProcessingIdentity"
				>
					{{ btnConfirmIdentityText }}
				</tk-link>
			</div>

			<div
				v-if="!isConfirmedIdentity && !isInProcessingIdentity"
				class="input-wrapper"
			>
				<p class="input-wrapper__text">
					Это не займет много времени. Подтверждая свою личность, Вы сохраняете
					безопасность сервиса.
				</p>
			</div>

			<h4 class="profile-form__title">Дополнительные данные</h4>

			<div class="input-wrapper">
				<router-link class="profile-form__contact-btn" to="anketa/contact-data">
					<span>Контактное лицо для связи при чрезвычайных ситуациях</span>
					<tk-svg type="arrow-top" />
				</router-link>
			</div>

			<tk-button
				kind="old-main"
				class="profile-form__submit-btn"
        :disabled="inProcess"
				@click="submit"
			>
				Сохранить
			</tk-button>
		</form>
	</div>
</template>

<script>
import errorHandler from '@/utils/errorHandler'

import Questions from '@/components/views/profile/Questions'
import Hobbies from '@/components/views/profile/Hobbies'

import modals from '@/constants/modals'
import { SET_FIELD } from '@/constants/store/user/mutations'
import { GET } from '@/constants/store/user/actions'

// Refactoring

export default {
	components: { Questions, Hobbies },
	mixins: [errorHandler],
	data() {
		return {
			incredibleActive: false,
			progress: 0,
			career: {},
			about: {},
			hobbies: [],
			questions: {},

			// Form
			gender: '',
			surname: '',
			name: '',
			patronymic: '',
			email: '',
			birth: '',
			phone: '',

      getAbortController: new AbortController(),
      inProcess: false,
		}
	},
	created() {
		this._createFieldsError(['name', 'surname', 'patronymic', 'email', 'phone'])
	},
	async beforeMount() {
		await this.$store.dispatch(GET).then(() => {
			this.birth = this.user.birth
			this.gender = this.user.gender
			this.hobbies = this.user.hobbies

			// fio
			this.name = this.user.name
			this.surname = this.user.surname
			this.patronymic = this.user.patronymic

			// Email and phone
			this.email = this.user.email
			this.phone = this.user.phone
		})

		const response = await this.$api.questions.get({payload: this.$store.getters.mode}, {controller: this.getAbortController})

		if (response && response.status) {
			this.career = response.response[0]
			this.about = response.response[1]
		}
	},
	computed: {
		genders() {
			return [
				{ label: 'Муж', value: 'MALE' },
				{ label: 'Жен', value: 'FEMALE' }
			]
		},
		user() {
			return this.$store.state.user
		},
		isConfirmedIdentity() {
			return this.user.account_confirmation === 'CONFIRMED'
		},
		isInProcessingIdentity() {
			return this.user.account_confirmation === 'IN_PROCESSING'
		},
    isRejectedIdentity() {
      return this.user.account_confirmation === 'REJECTED'
    },
		btnConfirmIdentityText() {
			if (this.isConfirmedIdentity) {
				return 'Аккаунт верифицирован'
			} else if (this.isInProcessingIdentity) {
				return `На проверке`
			} else if (this.isRejectedIdentity) {
        return 'Документы отклонены. Добавьте другие фотографии.'
      } else {
				return 'Подтвердить свою личность'
			}
		}
	},
	methods: {
		showChangePhone(props) {
			this.$store.commit('showModal', {
				name: modals.CHANGE_PHONE,
				props,
				isShow: true
			})
		},
		async submit() {
      this.inProcess = true

			const payload = {
				name: this.name,
				surname: this.surname,
				patronymic: this.patronymic,
				email: this.email,
				gender: this.gender,
				hobbies: this.hobbies,
				birth: this.birth
			}

			const response = await this.$api.user.update(payload)

			if (response && !response.status) {
        let message = ''
        for (let errorKey in response.errors) {
          message += response.errors[errorKey]
          break
        }
        this.$store.commit('showNotification', {
          type: 'error',
          text: message
        })
        this.inProcess = false
				return
			}

			Object.keys(payload).forEach((key) => {
				this.$store.commit(SET_FIELD, {
					field: key,
					value: response.response.user[key]
				})
			})

			// If all fio is empty
			this.surname = response.response.user.surname

			try {
				const res = await Promise.all(
					Object.entries(this.questions).map(
						async ([key, value]) =>
							await this.$api.questions.set({ id: key, answer: value })
					)
				)

				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Данные успешно обновлены'
				})
			} catch (e) {
				console.error('Error update questions: ', e)
			} finally {
        this.inProcess = false
      }
		},
		getPhone(payload) {
			return payload.replace(/[^+\d]/g, '')
		},
		changePhone() {
			const phoneInFormat = this.getPhone(this.phone)

			if (!this.phone || phoneInFormat === this.user.phone) {
				return
			}

			this.showChangePhone({
				phone: phoneInFormat,
				reject: () => {
					this.phone = this.user.phone
				}
			})
		},
		updateQuestion(val, flag) {
			// flag: true - career

			const update = (payload) =>
				payload.map((itm) =>
					itm.id === +Object.keys(val)[0]
						? { ...itm, answer: val[Object.keys(val)[0]] }
						: itm
				)

			if (flag === true) {
				this.career.questions = update(this.career.questions)
			} else if (flag === false) {
				this.about.questions = update(this.about.questions)
			}

			Object.assign(this.questions, val)
		}
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>

<style lang="sass">
.profile-anketa
  &__title
    margin-bottom: 45px

    font-weight: bold
    font-size: 24px
    line-height: 24px
    color: $black-secondary

  .dashboard
    width: 126px
    position: absolute
    top: 55px
    left: 30px

    .el-progress-circle
      &__track
        stroke: $gray-text

      &__path
        stroke: $green

  .profile-fullness
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 185px
    margin-bottom: 30px

    &__photo
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 100px
      height: 100px
      margin-bottom: 5px
      background-color: $gray-text
      border-radius: 50%
      overflow: hidden
      img
        object-fit: cover
      &:before
        content: url('~@/assets/img/svg/camera-white.svg')
        position: absolute

      &--avatar
        img
          object-fit: cover

    &__is-filled-in
      font-weight: 500
      font-size: 14px
      line-height: 14px
      color: $gray-text

  .profile-form
    max-width: 420px
    &__title
      padding: 0
      margin-bottom: 10px

      font-size: 14px
      line-height: 16px
      font-weight: 600
      color: $black-secondary

    .input-wrapper
      position: relative
      width: 100%
      margin-bottom: 15px
      &__text
        margin: 0
        padding-right: 30px
        font-size: 14px
        line-height: 16px
        color: $gray-text
      &__change
        position: absolute
        top: 0
        bottom: 0
        right: 20px

        display: flex
        align-items: center
        margin: auto 0

        font-size: 16px
        line-height: 16px
        color: $green

        cursor: pointer

      &__placeholder
        position: absolute
        left: 15px
        top: 20px

        z-index: 1

      &__input
        position: relative

        display: flex
        align-items: center
        padding: 5px 15px
        width: 100%
        height: 55px
        max-height: 55px
        font-size: 16px
        line-height: 16px
        caret-color: $green
        color: $black-secondary

        background-color: white
        border: 1px solid #E2E2E2
        border-radius: 12px

        .el-date-editor.el-input
          width: 100%

          .el-input
            &__wrapper
              box-shadow: none

            &__inner
              padding: 0

              text-align: end
              color: $green
              font-size: 16px
              line-height: 16px

              border: none
              background: transparent

              &::placeholder
                color: $green

            &__prefix
              display: none

        .select
          --el-color-primary: $brown

          margin-left: -15px
          margin-right: -15px
          width: 108%
          height: 100%
          background: transparent
          box-shadow: unset

          .select-trigger
            height: 100%

          .el-select__caret.el-input__icon.el-icon-arrow-up
            display: none

          .el-input__wrapper
            border: none
            box-shadow: none !important

          .el-input__inner
            padding: 0 15px
            font-size: 16px
            line-height: 16px
            color: $green
            background: transparent
            text-align: end

            &::placeholder
              color: $green

          .el-select__popper.el-popper
            top: 53px !important

      &__select
        position: relative
        margin-top: 20px
        margin-bottom: 20px
        width: 100%
        display: flex
        align-items: center
        font-size: 14px
        line-height: 16px
        font-weight: 600
        color: #3B3F47

    &__line
      display: block
      margin-top: 20px
      margin-bottom: 40px
      width: 100%
      height: 1px
      background-color: rgba(0, 0, 0, 0.1)

    &__contact-btn
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      height: 55px
      padding: 0 15px
      font-size: 12px
      text-decoration: none
      color: #3B3F47
      line-height: 12px
      border-radius: 11px
      box-shadow: 0px 0px 17px -6px lightgrey
      background-color: white
      box-sizing: border-box
      z-index: 0

      span
        max-width: 160px

      .svg-icon
        transform: rotate(90deg)

    &__submit-btn
      width: 100%
      height: 55px

@media screen and (max-width: 912px)
  .profile-anketa
    .profile-form

      &__description
        padding-right: 0
@media screen and (max-width: 800px)
  .profile-anketa
    .profile-form
      max-width: 100%
    .profile-fullness
      margin: 0 auto 30px auto
    .dashboard
      left: calc(50% - 63px)
</style>
