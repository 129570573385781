<template>
	<div class="input-wrapper">
		<!-- <tk-drop-down animation="slide-fade"> -->
		<!-- <template v-slot:toggle="{ active }"> { active: active } -->
		<div class="input-wrapper__select">
			{{ content.text }}
		</div>
		<!-- </template> -->

		<!-- <template v-slot:content> -->
		<!-- <div class="question-dropdown"> -->
			<component
				:is="component"
				class="question-dropdown__text"
				placeholder="Текст"
				:value="content.answer"
				@input="(payload) => (value = payload.target.value)"
				rows="2"
			/>
			<!-- <button type="button" class="question-dropdown__submit" @click="submit">
				Готово
			</button> -->
		<!-- </div> -->
		<!-- </template> -->
		<!-- </tk-drop-down> -->
	</div>
</template>

<script>
// import eventBus from '@/plugins/eventBus'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({
				id: 1,
				input_type: 'TEXTAREA',
				text: '',
				answer: ''
			})
		}
	},
	emits: ['update'],
	watch: {
		content: {
			handler() {
				this.value = this.content.answer
			},
			deep: true
		},
    value() {
      this.$emit('update', this.value)
    }
	},
	data() {
		return {
			value: null
		}
	},
	computed: {
		component() {
			// Get from Back two types TEXT/TEXTAREA
			return this.content.input_type === 'TEXT'
				? 'input'
				: this.content.input_type
		}
	},
	methods: {
		submit() {
			this.$emit('update', this.value)

			// eventBus.trigger('dropdown-hide')
		}
	}
}
</script>
