<template>
	<div class="questions__title">{{ content.text }}</div>

	<div
		class="questions__item"
		v-for="option in content.options"
		:key="option.id"
	>
		<tk-radio-button
			@change="change"
			:label="option.text"
			:checked="option.id === +content.answer"
			:id="getId(content.id, option.id)"
			:name="'communication-' + content.id"
			:value="option.id"
		/>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Object,
			default: () => ({
				id: 1,
				input_type: 'RADIO',
				text: '',
				answer: '',
				options: {
					id: 1,
					text: ''
				}
			})
		}
	},
	methods: {
		getId(parentId, childId) {
			return `radio-question-${parentId}-${childId}`
		},
		change(evt) {
			this.$emit('update', evt.target.value)
		}
	},
	emits: ['update']
}
</script>
