<template>
	<div class="questions">
		<div class="questions__title">{{ content.text }}</div>

		<div v-if="content.text_description" class="questions__description">
			{{ content.text_description }}
		</div>

		<template
        class="questions__item"
        v-for="itm in content.questions" :key="itm.id">
			<component
				:is="itm.input_type"
				:content="itm"
				@update="update(itm.id, $event)"
			/>
		</template>
	</div>
</template>

<script>
import radio from './components/radio'
import text from './components/text'

export default {
	components: {
		TEXT: text,
		TEXTAREA: text,
		RADIO: radio
	},
	props: {
		content: {
			type: Object,
			default: () => ({
				text: '',
				text_description: '',
				questions: [
					{
						id: 1,
						input_type: '',
						text: '',
						answer: ''
					}
				]
			})
		}
	},
	watch: {
		obj: {
			handler() {
				this.$emit('update', { ...this.obj })
			},
			deep: true
		}
	},
	data() {
		return {
			obj: {}
		}
	},
	methods: {
		update(key, val) {
			this.obj[key] = val + ''
		}
	},
	emits: ['update']
}
</script>
<style lang="scss" >
//.input-wrapper__select{
//  margin: 20px 0;
//  font-size: 14px;
//  line-height: 16px;
//  font-weight: 600;
//  color: #3B3F47;
//}
//.question-dropdown__text{
//  margin-top: 15px;
//}
</style>
