<template>
	<div class="profile-hobby">
		<h3 class="profile-hobby__title">Мои интересы</h3>

		<ul class="profile-hobby__list">
			<li v-for="item in content" :key="item.id" class="profile-hobby__item">
				<span class="profile-hobby__name">{{ item.title }}</span>
				<button
					@click="deleteItem(item.id)"
					type="button"
					class="btn profile-hobby__del-btn"
				>
					<svg
						width="11"
						height="10"
						viewBox="0 0 11 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.92235 5.00031L10.1427 1.90668C10.4985 1.56487 10.4985 1.0107 10.1427 0.669421L9.71337 0.257001C9.35744 -0.0849194 8.78058 -0.0849194 8.42532 0.257001L5.2051 3.35052L1.98477 0.25636C1.62896 -0.0854535 1.0521 -0.0854535 0.696838 0.25636L0.26686 0.66878C-0.0889533 1.0107 -0.0889533 1.56487 0.26686 1.90615L3.48775 5.00031L0.267527 8.09383C-0.0883973 8.43575 -0.0883973 8.98992 0.267527 9.3312L0.696838 9.74362C1.05265 10.0854 1.62951 10.0854 1.98477 9.74362L5.2051 6.64999L8.42532 9.74362C8.78125 10.0854 9.35811 10.0854 9.71337 9.74362L10.1427 9.3312C10.4985 8.98928 10.4985 8.43511 10.1427 8.09383L6.92235 5.00031Z"
							fill="black"
							fill-opacity="0.1"
						/>
					</svg>
				</button>
			</li>
		</ul>

		<form @submit.prevent="add">
			<div class="profile-hobby__search">
				<tk-input v-model="inpt"
                  placeholder="Что еще тебе интересно? (футбол, музыка, ...)"
                  :is-clear-button="false"
                  is-send-button
                  @send="add" />
			</div>
			<!-- <tk-button type="submit" kind="old-main" class="profile-hobby__add-btn">
				Добавить
			</tk-button> -->
		</form>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			inpt: ''
		}
	},
	computed: {},
	methods: {
		async add() {
			if (this.content.length >= 10) {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Можно добавить не более 10 штук!'
				})

				return
			}

			const response = await this.$api.hobby.add({ title: this.inpt })

			if (response?.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно добавлено'
				})

				this.$emit('update', [...this.content, { ...response.response }])

				this.inpt = ''
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Ошибка добавления хобби!'
				})
			}
		},
		async deleteItem(id) {
			const response = await this.$api.hobby.del({ id })

			if (response?.response.is_deleted) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно удалено'
				})

				this.$emit(
					'update',
					this.content.filter((itm) => itm.id !== id)
				)
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Ошибка удаления хобби!'
				})
			}
		}
	}
}
</script>

<style lang="sass">
.profile-hobby
  &__title
    margin: 0
    margin-bottom: 20px
    font-weight: 600
    font-size: 14px
    line-height: 16px
    color: #3B3F47

  &__list
    margin-bottom: 20px

  &__item
    display: flex
    align-items: center
    justify-content: space-between
    padding: 12px
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)

  &__del-btn
    position: relative
    width: 11px
    height: 10px

  &__add-btn
    width: 180px
    height: 45px
    margin: 20px 0
</style>
